import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
// import { useNavigate } from "react-router-dom";
import { Box, Image } from "@chakra-ui/react";

import { Navigation, EffectCoverflow, Autoplay } from "swiper/modules";

import "./style.scss";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-coverflow";

// import useFetch from "../../../hooks/UseFetch";
import "swiper/css";

const HeroBanner = () => {
  const [background, setBackground] = useState([]);
  const bg1 = 'https://turkishfam.com/public/images/kendi-dusen-aglamaz-widescreen.jpg';
  const bg2 = 'https://turkishfam.com/public/images/ruhun-duymaz-widescreen.jpg';
  const bg3 = 'https://turkishfam.com/public/images/safir-widescreen.jpg';
  const bg4 = 'https://turkishfam.com/public/images/yabani-widescreen.jpg';
  // const [query, setQuery] = useState("");
  // const navigate = useNavigate();

  // const formData = new FormData();
  // formData.append('function','dramas');
  // const {data, loading} = useFetch("/dramas.php", formData);

  useEffect(() => {
    setBackground([bg1,bg2,bg3,bg4]);
    // const bg =
    //   "https://turkishfam.com/public/images/Kendi_D%C3%BCsen_Aglamaz_widescreen.jpg";
    console.log(background);
    // setBackground(bg);
  }, []);

  {
    /* <SwiperSlide>
    <Image src={background} blur={5} 
    style={{width: '100%', height: '90%', display: 'block', objectFit: 'cover'}}
    />
  </SwiperSlide> */
  }
  return (
    <Box>
      
      <Swiper
        navigation={true}
        modules={[Navigation, EffectCoverflow, Autoplay]}
        className="mySwiper"
        style={{ width: "100%" }}
        effect="coverflow"
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={'2'}
        loop={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        coverflowEffect={{
          rotate: 0,
          stretch: 100,
          depth: 200,
          modifier: 1,
          slideShadows: true,
        }}
      >

    {background.map((image, index) => (
        <SwiperSlide
          key={index}
          style={{
            backgroundSize: "cover",
            backgroundPosition: "50%",
            minHeight: "60vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            backgroundImage: `url(${image})`,
          }}
        ></SwiperSlide>
    ))}
      </Swiper>
    </Box>
  );
};

export default HeroBanner;
