import React from 'react';
import DramaHeader from '../../../components/Dramas/DramaHeader';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import useFetch from "../../../hooks/useFetch";
import { useState } from 'react';
import { Box } from '@chakra-ui/react';
import MyHeading from '../../../components/heading/MyHeading';

const SingleEpisode = () => {
    const [drama, setDrama] = useState("");
    const [episode, setEpisode] = useState("");
    const { slug, number } = useParams();
    
    const {data: dramaData, loading: dramaLoading} = useFetch("/dramas.php?function=drama&slug="+slug);
    const {data: episodeData, loading: episodeLoading} = useFetch("/dramas.php?function=get_episode&slug="+slug+"&episode="+number);

    useEffect(() =>{
        
            if (dramaData && dramaData.drama) {
    
                setDrama(dramaData.drama);
            }
        console.log(dramaData);
    },[dramaData]);

    useEffect(() =>{
        if (episodeData && episodeData.episode) {

            setEpisode(episodeData.episode);
        }
        console.log(episodeData);
    },[episodeData]);


  return (
    <div>
        { !dramaLoading ? (
            <div>
                <DramaHeader drama={drama}/>
                { !episodeLoading ?(

                    <Box>
                        <MyHeading heading={"Episode "+episode?.episode_number}/>
                        <p>{episode?.episode_desc}</p>
                        <h3>Trailer</h3>
                    </Box>
                ): (
                    <p>Loading</p>
                )}
            </div>
        ) : (
            <p>Loading</p>
        )

        }
    </div>
  )
}

export default SingleEpisode