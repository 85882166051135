import React, { Component } from 'react';
import { Link as ReactRouterLink } from "react-router-dom";
import {
  Box,
  Text,
  Link as ChakraLink,
} from "@chakra-ui/react";
import OPImage from '../opimage/OPImage';

export class CelebItem extends Component {
    render() {

        let {imageLink,celebName,slug} = this.props;

    return (
      <ChakraLink as={ReactRouterLink} to={`/celeb/${slug}`}>
        <Box>
          <OPImage image={imageLink} alt={celebName} />
          <Text variant="xl">{celebName}</Text>
        </Box>
      </ChakraLink>
    )
  }
}

export default CelebItem