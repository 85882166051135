import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import Home from './pages/home/Home';
import Dramas from './pages/dramas/Dramas';
import Login from './common/Login';
import Signup from './common/Signup';
import Celebs from './pages/celebs/Celebs';
import SingleCeleb from './components/Celebs/SingleCeleb';
import SingleDrama from './pages/dramas/singleDrama/SingleDrama';
import EpisodesBySlug from './components/Dramas/EpisodesBySlug';
import SearchResult from './pages/searchResult/SearchResult';
import SingleEpisode from './pages/episode/singleEpisode/SingleEpisode';
import CastCrew from './pages/dramas/castCrew/CastCrew';
import Header2 from './components/layouts/header/Header2';
import Footer from './components/footer/Footer';

function App() {
  return (
    <BrowserRouter>
      <Header2/>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/dramas" element={<Dramas/>} />
        <Route path="/celebs" element={<Celebs/>} />
        <Route path="/login" element={<Login/>} />
        <Route path="/signup" element={<Signup/>} />
        <Route path="/drama/:slug" element={<SingleDrama/>}/>
        <Route path="/drama/:slug/episodes" element={<EpisodesBySlug/>} />
        <Route path="/drama/:slug/cast" element={<CastCrew/>} />
        <Route path="/celeb/:slug" element={<SingleCeleb/>} />
        <Route path="/drama/:slug/episode/:number" element={<SingleEpisode/>} />
        <Route path="/search/:query" element={<SearchResult/>} />
      </Routes>
      <Footer/>
    </BrowserRouter>
  );
}

export default App;
