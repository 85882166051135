import React, { useState } from 'react';

function ReadMore({ text, maxLength }) {

  const [showFullText, setShowFullText] = useState(false);

  const toggleText = () => {
    setShowFullText(!showFullText);
  };

  if (!text || typeof text !== 'string' || text.length <= maxLength) {
    return <p>{text || 'Not found'}</p>;
  }

  return (
    <div>
      <p className='text-justify'>{showFullText ? text : `${text.slice(0, maxLength)}...`}
        <strong className='' onClick={toggleText}>
          {showFullText ? ' read less.' : ' read more.'}
        </strong>
      </p>
      
    </div>
  );
}

export default ReadMore;