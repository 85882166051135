import { createSlice } from "@reduxjs/toolkit";

export const globalSlice = createSlice ({
    name: 'global',
    initialState: {
        drama: {},
    },
    reducers:{
        getDrama: (state, action) => {
            state.drama = action.payload;
        }
    }
})

export const { getDrama } = globalSlice.actions;

export default globalSlice.reducer;