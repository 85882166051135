import axios from "axios";

const BASE_URL = "https://api.downloadhdyoutubethumbnail.com/v1";

export const fetchDataFromAPI = async (url) => {
  try {
    const { data } = await axios.get(BASE_URL + url, {
      headers: { API_KEY: "123" },
    });

    return data;
  } catch (error) {
    console.log(error);
    return error;
  }
};
