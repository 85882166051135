import { Image, Skeleton } from '@chakra-ui/react';
import React, { useState } from 'react'

const OPImage = ({image, alt}) => {
  const [imageLoaded, setImageLoaded] = useState(false);
    const imageMobile = image.replace(/(\.[^/.]+)$/, '-low$1');
    const imageTab = image.replace(/(\.[^/.]+)$/, '-med$1');
    const imageDesk = image.replace(/(\.[^/.]+)$/, '-high$1');

    const screenWidth = window.innerWidth;

    let selectedImage;

    if (screenWidth < 450) {
        selectedImage = imageMobile;
      } else if (screenWidth < 1000) {
        selectedImage = imageTab;
      } else {
        selectedImage = imageDesk;
      }

  return (
    <>
    <Skeleton isLoaded={imageLoaded} height="100%" width="100%">
        <Image src={selectedImage} alt={alt} onLoad={() => setImageLoaded(true)} />
        </Skeleton>
    </>
  )
}

export default OPImage