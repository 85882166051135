import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import CelebOverview from "./CelebOverview";
import useFetch from "../../hooks/useFetch";

function SingleCeleb() {
  const { slug } = useParams();
  const [celeb, setCeleb] = useState(null);
  const { data, loading } = useFetch("/celebs.php?function=get_celeb_by_slug&slug="+slug);

  useEffect(() => {
    if (data && data.celeb) {
      setCeleb(data.celeb);
    }
  }, [data]);

  return (
    <div>
      {celeb && !loading ? (
        <div className="container">
          <CelebOverview celeb={celeb} />
        </div>
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
}

export default SingleCeleb;
