import { Box, Heading } from '@chakra-ui/react';
import React from 'react';

const MyHeading = ({heading}) => {
  return (
    <Box>
      <Box borderLeft="5px solid #ada334" paddingLeft="5px" marginBottom={2}>
        <Heading as="h2" fontSize={{ base: 'xl', md: '3xl' }} textAlign="left">
          {heading}
        </Heading>
      </Box>
    </Box>
  )
}

export default MyHeading