import React, { Component } from 'react'
import CustomNavbar from './CustomNavbar'

export class Base extends Component {
  render() {
    const { title = "TurkFam", children } = this.props;
    return (
        <div className="container-fluid p-0">
            <CustomNavbar title={title} />
            {children}
        </div>
    )
  }
}

export default Base