import React from 'react';
import { Box, Img, SimpleGrid } from "@chakra-ui/react";

const Cast = ({cast}) => {



  return (
    <div>
      {cast ? (
        <Box>
          <SimpleGrid p={5} spacing={5} minChildWidth={200}>
          {cast?.map((celeb) => (
            <Box key={celeb.celeb_id}>
              <Img src={celeb.poster_image}/>
              <p>{celeb.celebrity_name}</p>
            </Box>
          ))}
          </SimpleGrid>
        </Box>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  )
}

export default Cast