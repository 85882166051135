import React, { useEffect, useState } from 'react';
import HeroBanner from './heroBanner/HeroBanner';
import DramaSwiper from '../../components/swipers/DramaSwiper';
import { Box, VStack } from '@chakra-ui/react';
import MyHeading from './../../components/heading/MyHeading';
import useFetch from '../../hooks/useFetch';

const Home = () => {

  const [dramas, setDramas] = useState([]);

  const { data, loading } = useFetch("/dramas.php?function=dramas");

  useEffect(() => {
    if (data && data.dramas) {
      setDramas(data.dramas);
    }
  }, [data]);

  return (
    <Box>
      <VStack align='stretch'>
        <HeroBanner/>
        <Box margin={[0,3]}>
        <MyHeading heading={"Trending Dramas"} />
        <DramaSwiper dramas={dramas}/>
        <MyHeading heading={"Recently Added"} />
        <DramaSwiper dramas={dramas}/>
        {/* <MyHeading heading={"Romantic Dramas"} />
        <DramaSwiper/>
        <MyHeading heading={"First Time Watchers"} />
        <DramaSwiper/>
        <MyHeading heading={"Classic Dramas"} />
        <DramaSwiper/> */}
        </Box>
      </VStack>
    </Box>
  )
}

export default Home