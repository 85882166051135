import React, { Component } from 'react'

import Base from './Base'

export class Signup extends Component {
  constructor(props){
    super(props);

    this.state = {
      user: {
        f_name: '',
        l_name: '',
        email: '',
        password: '',
      },
      error: {
        errors: {},
        isError: false,
      },
    };
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        [name]: value,
      },
    }));
  };

  handleSubmit = (event) => {
    event.preventDefault();
    console.log(this.state.user);
    // Perform any form submission or validation here
  };


  render() {

    const { user } = this.state;

    return (
      <Base>
        <div className='container mt-2'>
          <h2>Signup</h2>
          <div className="d-flex justify-content-center card p-3 shadow">
            <form action="" className='form' onSubmit={this.handleSubmit}>
              <div className="mb-2">
                <label htmlFor="f_name">First Name</label>
                <input 
                  type="text" 
                  className='form-control' 
                  name='f_name'
                  value={user.f_name}
                  onChange={this.handleChange} />
              </div>
              <div className="mb-2">
                <label htmlFor="l_name">Last Name</label>
                <input type="text" className='form-control' name='l_name' value={user.l_name} onChange={this.handleChange} />
              </div>
              <div className="mb-2">
                <label htmlFor="email">Email</label>
                <input type="email" className='form-control' name='email' value={user.email} onChange={this.handleChange} />
              </div>
              <div className="mb-2">
                <label htmlFor="password">Password</label>
                <input type="password" className='form-control' name='password' value={user.password} onChange={this.handleChange} />
              </div>
              <button className='btn btn-primary' type='submit'>Signup</button>
            </form>
        </div>
        </div>

      </Base>
    )
  }
}

export default Signup