import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import { Box, Image } from "@chakra-ui/react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from 'swiper/modules';

import "swiper/css";
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import './DramaEpisodes.scss';

const DramaEpisodes = ({ dramaCode, image, slug }) => {
  const [episodes, setEpisodes] = useState(null);
  const navigate = useNavigate();

  const { data, loading } = useFetch(
    "/dramas.php?function=episodes_by_drama_code&drama_code=" + dramaCode
  );

  useEffect(() => {
    if (data && data.episodes) {
      setEpisodes(data.episodes);
    }
  }, [data]);

  if (!episodes) {
    return <div>Loading...</div>; // Or any other loading indicator
  }

  return (
    <>
    {!loading && episodes ? (

    <Box>
      <Swiper slidesPerView={"auto"} spaceBetween={10} className="episodeSwiper" navigation={true} modules={[Navigation]}>
        {episodes.map((episode, index) => (
            <SwiperSlide
              key={uuidv4(index)}
              onClick={()=>navigate(`/drama/${slug}/episode/${episode.episode_number}`)}
            >
              <Image src={image} />
              Episode {episode.episode_number}
            </SwiperSlide>
        ))}
      </Swiper>

    </Box>
    ) : (
      <Box>Loading...</Box>
    )}
    </>
  );
};

export default DramaEpisodes;
