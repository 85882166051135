import React from 'react'
import { useState, useEffect } from 'react'
import useFetch from '../../../hooks/useFetch';
import { useParams } from 'react-router-dom';
import DramaHeader from '../../../components/Dramas/DramaHeader';
import Cast from '../../../components/Dramas/Cast';

const CastCrew = () => {

    const { slug } = useParams();
    const [drama, setDrama] = useState(null);
    const [cast, setCast] = useState(null);

    const { data: dramaData, loading: dramaLoading } = useFetch("/dramas.php?function=drama&slug="+slug);
    const { data: castData, loading: castLoading } = useFetch("/dramas.php?function=get_celeb_by_slug&slug="+slug);

    useEffect(() => {
      if (dramaData && dramaData.drama) {
        setDrama(dramaData.drama);
      }
    },[dramaData]);

    useEffect(() => {
      if (castData && castData.celebs) {
        setCast(castData.celebs);
      }
    },[castData]);

  return (
    <div>
      {drama && !dramaLoading ? (
        <div>
          <DramaHeader drama={drama} />
          <Cast cast={cast} />
        </div>
      ) : (
        <div>Loading...</div>
      ) }
    </div>
  )
}

export default CastCrew