import React, { Component } from 'react'
import Base from './Base'

export class Login extends Component {
  render() {
    return (
      <Base>
        <div className='container mt-2'>
          <h2>Login</h2>
          <div className="d-flex justify-content-center card p-3 shadow">
            <form action="" className='form'>
              <div className="mb-2">
                <label htmlFor="email">Email</label>
                <input type="email" className='form-control' name='email' />
              </div>
              <div className="mb-2">
                <label htmlFor="password">Password</label>
                <input type="password" className='form-control' name='password' />
              </div>
              <button className='btn btn-primary' type='button'>Login</button>
            </form>
        </div>
        </div>

      </Base>
    )
  }
}

export default Login