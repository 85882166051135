import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Box, Link as ChakraLink, Skeleton } from "@chakra-ui/react";
import { Link as ReactRouterLink } from "react-router-dom";

import "swiper/css";
import OPImage from "../opimage/OPImage";

const CastSwiper = ({ celebs }) => {

  return (
    <Box m={1}>
      {celebs && celebs.length > 0 ? (
        <Swiper slidesPerView={"auto"} spaceBetween={10} className="castSwiper">
            {celebs.map((celeb, index) => (
            <SwiperSlide key={index} >
                <ChakraLink as={ReactRouterLink} to={`/celeb/${celeb.slug}`}>
                <OPImage image={celeb.poster_image} />
                </ChakraLink>
            </SwiperSlide>
            ))}
        </Swiper>
        ) : celebs && celebs.length === 0 ? (
        <Box>No cast found</Box>
        ) : (
        <Skeleton height="200px" width="100%" />
        )}
    </Box>
  );
};

export default CastSwiper;
