import React from 'react'
import { Flex, Spacer, HStack } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";

const Header2 = () => {
  return (
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      padding="1rem"
      px={10}
    >
      <NavLink to="/" fontSize="xl" fontWeight="bold">
        TurkishFam
      </NavLink>

      <Spacer />

      <HStack spacing="1rem">
        <NavLink to="/">Home</NavLink>
        <NavLink to="/dramas">Dramas</NavLink>
        <NavLink to="/celebs">Celebs</NavLink>
      </HStack>
    </Flex>
  )
}

export default Header2