import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Box, Link as ChakraLink, Skeleton } from "@chakra-ui/react";
import { Link as ReactRouterLink } from "react-router-dom";

import "./style.scss";
import "swiper/css";
import OPImage from "../opimage/OPImage";

const DramaSwiper = ({ dramas }) => {

  return (
    <Box m={1}>
      {dramas ? (
          <Swiper slidesPerView={"auto"} spaceBetween={10} className="swiper-container">
            {dramas.map((drama, index) => (
              <SwiperSlide key={index}>
                  <ChakraLink as={ReactRouterLink} to={`/drama/${drama.slug}`}>
                  <OPImage image={drama.poster_image} />
              </ChakraLink>
                </SwiperSlide>
            ))}
          </Swiper>

      ) : (
        <Skeleton height="200px" width="100%" />
      )}
    </Box>
  );
};

export default DramaSwiper;
