import { Box, HStack, Heading, Img, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

function DramaHeader({ drama }) {
  const [posterHeight, setPosterHeight] = useState('80%'); // Default height for the poster

  const location = useLocation();

  const navLinks = [
    { to: `/drama/${drama.slug}`, label: 'Overview' },
    { to: `/drama/${drama.slug}/episodes`, label: 'Episodes' },
    { to: `/drama/${drama.slug}/cast`, label: 'Cast & Crew' },
    { to: `/drama/${drama.slug}/reviews`, label: 'Reviews' },
    { to: `/drama/${drama.slug}/news`, label: 'News' },
    { to: `/drama/${drama.slug}/watch`, label: 'Where to Watch' },
  ];

  useEffect(() => {
    const widescreenImage = new Image();
    widescreenImage.src = drama.widescreen;

    widescreenImage.onload = () => {
      const calculatedHeight = (widescreenImage.height / widescreenImage.width) * 100 * 1.3 + '%';
      setPosterHeight(calculatedHeight);
      console.log(calculatedHeight);
    };
  }, [drama.widescreen]);


  if (!drama || !drama.genres) {
    return <div>Loading...</div>;
  }


  return (
    <div>
      <Box pos="relative">
        <Img src={drama.widescreen} w="100vw" h={['250','300','400']} objectFit={"cover"} />
        <Box
          pos="absolute"
          top="0"
          left="0"
          w="100%"
          h="100%"
          bg="rgba(0, 0, 0, 0.7)"
          display="flex"
          justifyContent="center"
          alignItems="center"
        ></Box>
        <Img src={drama.poster} borderRadius={5} pos="absolute" transform="translate(10%,-50%)" h={posterHeight} top='50%' left='0' />
        <Heading
          color="white"
          pos="absolute"
          top={['10vw', '8vw', '8vw', '6vw', '6vw']}
          left={['39vw', '36vw', '30vw', '25vw', '20vw']}
          fontSize={['xl', '2xl', '3xl', '4xl']}
          as="h1"
          noOfLines={1}
        >
          {drama.drama_name}
        </Heading>
        <HStack pos="absolute" top={["16vw", "14vw", "12vw", "10vw", "9vw"]} left={['39vw', '36vw', '30vw', '25vw', '20vw']}>
          {drama.genres.map((genre, index) => (
            <Text fontSize={['xs', 'sm', 'md', 'lg']} color={"whatsapp.100"} m={1} key={index}>{genre.genre_name}</Text>
          ))}
        </HStack>
      </Box>
      <HStack>
        
      </HStack>
      <HStack p={2} overflowX="auto" css={{ whiteSpace: 'nowrap' }}>
      {navLinks.map((navLink) => (
        <Box
          key={navLink.label}
          borderBottom={location.pathname === navLink.to ? '2px solid' : 'none'}
          borderColor={location.pathname === navLink.to ? 'blue.500' : 'none'}
        >
          <NavLink to={navLink.to}>{navLink.label}</NavLink>
        </Box>
      ))}
    </HStack>
    </div>
  );
}

export default DramaHeader;
