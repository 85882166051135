import React from 'react';
import ReadMore from './../../common/ReadMore';
import DramaEpisodes from './DramaEpisodes';
import { Box, Button, Stack } from '@chakra-ui/react';
import MyHeading from '../heading/MyHeading';
import SeriesCast from './SeriesCast';

const DramaOverview = ({ drama, slug }) => {

  console.log(drama.drama_desc);

  if (drama === null) {
    return <div>Loading...</div>;
  }

  return (
    <div>
        <Box>
          <Stack>
            <MyHeading heading={"Synopsis"}/>
            <ReadMore text={drama.drama_desc} maxLength={300} />

            <Box marginX={4}>
            <Button
              width="full" // This makes the button full-width
              as="a" // Use the 'as' prop to render the button as an anchor (a) element
              href="https://youtube.com"
              target="_blank"
            >
              Watch Trailer
            </Button>
            </Box>

            {/* <a href="https://youtube.com" target='blank'>Watch Trailer</a> */}

            <MyHeading heading={"Episodes"}/>
            <DramaEpisodes dramaCode={drama.drama_code} image={drama.widescreen} slug={slug}/>

            <MyHeading heading={"Series Cast"}/>
            {/* <SeriesCast dramaID={drama.drama_id}/> */}
            {/* <CastSwiper /> */}
            <SeriesCast slug={slug} />
            
            <MyHeading heading={"Reviews"}/>
            <MyHeading heading={"News"}/>
            <MyHeading heading={"Where to watch"}/>
            <MyHeading heading={"Related Dramas"}/>
          </Stack>
        </Box>
    </div>
  );
};

export default DramaOverview;
