import React, { Component } from "react";
import { Link as ReactRouterLink } from "react-router-dom";
// import Img from "../lazyLoadImage/Img";
import {
  Box,
  Text,
  Link as ChakraLink,
} from "@chakra-ui/react";
import OPImage from "../opimage/OPImage";

export class DramaItem extends Component {
  render() {
    let { imageLink, dramaName, slug } = this.props;
    return (

      <ChakraLink as={ReactRouterLink} to={`/drama/${slug}`}>
        <Box>
          <OPImage image={imageLink} alt={dramaName} />
          <Text variant="xl">{dramaName}</Text>
        </Box>
      </ChakraLink>
    );
  }
}

export default DramaItem;
