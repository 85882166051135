import React, { useState, useEffect } from "react";
import useFetch from "../../hooks/useFetch";
import DramaItem from "../../components/Dramas/DramaItem";
import { Box, SimpleGrid } from "@chakra-ui/react";

const Dramas = () => {
  const [dramas, setDramas] = useState([]);

  const { data, loading } = useFetch("/dramas.php?function=dramas");

  useEffect(() => {
    if (data && data.dramas) {
      setDramas(data.dramas);
    }
  }, [data]);

  return (
    <div>
      {!loading ? (
          <SimpleGrid columns={{ base: 2, sm: 2, md: 3, lg: 4, xl: 6 }} p={5} spacing={5}>
          {dramas.map((element) => (
            <DramaItem
              key={element.drama_id}
              dramaName={element.drama_name}
              imageLink={element.poster_image}
              slug={element.slug}
            />
          ))}
        </SimpleGrid>
      ) : (
        <Box>Data is loading</Box>
      )}
    </div>
  );
};

export default Dramas;
