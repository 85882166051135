import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DramaOverview from "../../../components/Dramas/DramaOverview"
import DramaHeader from "../../../components/Dramas/DramaHeader";
import useFetch from "../../../hooks/useFetch";

function SingleDrama() {
  const { slug } = useParams();
  const [ drama, setDrama ] = useState(null);

  const {data, loading} = useFetch("/dramas.php?function=drama&slug="+slug);

  useEffect(() => {
    if (data && data.drama) {
      setDrama(data.drama);
      console.log(data.drama);
    }
  },[data]);
  

  return (
    <div>
      {drama && !loading ? (
        <div>
          <DramaHeader drama={drama}/>
          <DramaOverview drama={drama} slug={slug}/>
        </div>
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
}

export default SingleDrama;