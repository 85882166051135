import { Box, Img, SimpleGrid } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import CelebItem from "../../components/Celebs/CelebItem";
import useFetch from "../../hooks/useFetch";
import { useNavigate } from "react-router-dom";

const Celebs = () => {
  const [celebs, setCelebs] = useState([]);
  const navigate = useNavigate();

  const { data, loading } = useFetch("/celebs.php?function=get_celebs");

  useEffect(() => {
    if (data && data.celebs) {
      setCelebs(data.celebs);
      console.log(data.celebs);
    }
  }, [data]);

  return (
    <div>
      {celebs && !loading ? (
        <Box>
          <SimpleGrid columns={{ base: 2, sm: 2, md: 3, lg: 4, xl: 6 }} p={5} spacing={5}>
            {celebs?.map((celeb) => (
              <CelebItem
              key={celeb.celeb_id}
              celebName={celeb.celebrity_name}
              imageLink={celeb.poster_image}
              slug={celeb.slug}
              />
            ))}
          </SimpleGrid>
        </Box>
      ) : (
        <Box>Loading...</Box>
      )}
    </div>
  );
};

export default Celebs;
