import React, { useState, useEffect } from "react";
import DramaHeader from "./DramaHeader";
import { useNavigate, useParams } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import { Box, Img, SimpleGrid } from "@chakra-ui/react";

const EpisodesBySlug = () => {
  const [drama, setDrama] = useState("");
  const [episodes, setEpisodes] = useState(null);
  const navigate = useNavigate();
  const { slug } = useParams();

  const { data: dramaData, loading: dramaLoading } = useFetch("/dramas.php?function=drama&slug="+slug);
  const { data: episodesData, loading: episodesLoading } = useFetch("/dramas.php?function=episodes_by_slug&slug="+slug);

  useEffect(() => {
    if (dramaData && dramaData.drama) {
      setDrama(dramaData.drama);
    }
  }, [dramaData]);

  useEffect(() => {
    if (episodesData && episodesData.episodes) {
      setEpisodes(episodesData.episodes);
      console.log(episodesData.episodes);
    }
  }, [episodesData]);

  return (
    <div>
      {!dramaLoading && !episodesLoading ? (
        <Box>
          <DramaHeader drama={drama} />
          <SimpleGrid p={5} spacing={5} minChildWidth={200}>
          {episodes?.map((episode) => (
            <Box key={episode.episode_number}>
              <Img src={drama.widescreen}/>
              <p>Episode {episode.episode_number}</p>
            </Box>
          ))}
          </SimpleGrid>
        </Box>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default EpisodesBySlug;
