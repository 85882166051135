import React from "react";
import ReadMore from "./../../common/ReadMore";
import { Box, Flex, Table, Tbody, Td, Tr } from "@chakra-ui/react";
import OPImage from "../opimage/OPImage";
import MyHeading from "../heading/MyHeading";
import TVShows from "./TVShows";

function CelebOverview({ celeb }) {
  return (
    <Box>
      <Flex
        direction={{ base: "column", md: "row" }}
        align={{ base: "center", md: "flex-start" }}
        style={{ justifyContent: { base: "flex-start", md: "center" } }}
        margin={{base: 0, md: 5}}
      >
        {/* Left side (image) */}
        <Box order={{ base: 1, md: 1 }} width={{base: 150, md: 200}} margin={{base: 5, md: 0}}>
          <OPImage image={celeb.poster_image} alt={celeb.celebrity_name} />
        </Box>

        {/* Right side (other contents) */}
        <Box
          marginLeft={{base: 0, md:5}}
          order={{ base: 2, md: 2 }}
          flex="1"
        >
          {/* Your other contents go here */}
          {/* For example: */}
          <Table variant="striped" width="full">
            <Tbody>
              <Tr>
                <Td>
                  <strong>Real Name:</strong>
                </Td>
                <Td>{celeb.celebrity_name}</Td>
              </Tr>
              <Tr>
                <Td>
                  <strong>Gender:</strong>
                </Td>
                <Td>{celeb.gender}</Td>
              </Tr>
              <Tr>
                <Td>
                  <strong>Acting Since:</strong>
                </Td>
                <Td>{celeb.active_since}</Td>
              </Tr>
              <Tr>
                <Td>
                  <strong>Birth Place:</strong>
                </Td>
                <Td>{celeb.birth_place}</Td>
              </Tr>
              <Tr>
                <Td>
                  <strong>Citizenship:</strong>
                </Td>
                <Td>{celeb.citizenship}</Td>
              </Tr>
              <Tr>
                <Td>
                  <strong>Education:</strong>
                </Td>
                <Td>{celeb.education}</Td>
              </Tr>
              <Tr>
                <Td>
                  <strong>Occupation:</strong>
                </Td>
                <Td>{celeb.occupation}</Td>
              </Tr>
              <Tr>
                <Td>
                  <strong>Zodiac:</strong>
                </Td>
                <Td>{celeb.zodiac}</Td>
              </Tr>
            </Tbody>
          </Table>
          {/* Add other content components as needed */}
        </Box>
      </Flex>
      <MyHeading heading="Biography"/>
      <ReadMore text={celeb.biography} maxLength={300} />
      <MyHeading heading="Early Life"/>
      <ReadMore text={celeb.early_life} maxLength={300} />
      <MyHeading heading="Career"/>
      <ReadMore text={celeb.career} maxLength={300} />
      <MyHeading heading="TV Shows"/>
      <TVShows />
    </Box>
  );
}

export default CelebOverview;
