import { Box } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import useFetch from '../../hooks/useFetch';
import CastSwiper from '../swipers/CastSwiper';

const SeriesCast = ({slug}) => {

    const {data, loading} = useFetch("/dramas.php?function=get_celeb_by_slug&slug="+slug);
    const [celebs, setCelebs] = useState([]);

    useEffect(() => {
        if (data && data.celebs) {
          setCelebs(data.celebs);
        }
      },[data]);

    return (
        <>
        { celebs && !loading ? 
            (
                <CastSwiper celebs={celebs}/>
                ) : 
                (
                    <Box></Box>
            )
            
        }
        </>
    )
}

export default SeriesCast